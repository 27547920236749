<template>
  <v-app>
    <layout-core-app-bar />
    <layout-core-drawer />
    <layout-core-view />
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',
    components: {
    },
    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
